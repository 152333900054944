<template>
    <el-container>
        <el-main>
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>供片系统管理方</el-breadcrumb-item>
                <el-breadcrumb-item>设备列表</el-breadcrumb-item>
                <el-breadcrumb-item>设备列表</el-breadcrumb-item>
            </el-breadcrumb>
            <SearchForm v-if="searchOptions.open" v-model="searchOptions.searchForm" :items="searchOptions.items" @search="handleSearch" :params="params">
                <el-button v-if="editOptions.open && !selector" type="primary" @click="editFormVisible = true">添加数据</el-button>
            </SearchForm>
            <template v-if="tableOptions.open">
                <el-table :data="tableOptions.tableData" stripe border size="small"
                           header-cell-class-name="table_header"
                          cell-class-name="table_cell" style="width: 100%">
                    <el-table-column type="index" label="序号" width="50"/>
                    <template v-if="tableOptions.items">
                        <template v-for="item in tableOptions.items">
                            <el-table-column v-if="item.type === Search_Type.Select" :label="item.label">
                                <template slot-scope="scope">
                                    <template v-if="item.props">
                                        <template v-if="item.props[scope.row[item.name]]">
                                            <template v-if="item.propsColors && item.propsColors[scope.row[item.name]]">
                                                <el-tag :type="item.propsColors[scope.row[item.name]]" disable-transitions>
                                                    {{item.props[scope.row[item.name]]}}
                                                </el-tag>
                                            </template>
                                            <template v-else>
                                                {{item.props[scope.row[item.name]]}}
                                            </template>
                                        </template>
                                        <template v-else-if="scope.row[item.name]">
                                            <el-tag type="danger" disable-transitions>
                                                {{scope.row[item.name]}}
                                            </el-tag>
                                        </template>
                                        <template v-else>
                                            {{scope.row[item.name]}}
                                        </template>
                                    </template>
                                    <template v-else>
                                        <el-tag type="warning" disable-transitions>
                                            {{scope.row[item.name]}}
                                        </el-tag>
                                    </template>
                                </template>
                            </el-table-column>
                            <el-table-column v-else-if="item.type === Search_Type.DynamicSelect" :label="item.label">
                                <template slot-scope="scope">
                                    <el-tag :type="g_getDynamicValue(scope.row[item.name],item, true)"
                                            disable-transitions>{{g_getDynamicValue(scope.row[item.name],item)}}</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column v-else :prop="item.name" :label="item.label"/>
                        </template>
                    </template>
                    <el-table-column min-width="200" label="操作">
                        <template slot-scope="scope">
                            <template v-if="!selector">
                                <template v-if="scope.row.status === 0">
                                    <el-button type="text" @click="forbidHandle(scope.row.id)">
                                        禁用证书 <i class="el-icon-close-notification"></i>
                                    </el-button>
                                    <el-button type="text" @click="updateRegHandle(scope.row.id)">
                                        修改预注册时间 <i class="el-icon-time"></i>
                                    </el-button>
                                </template>
                                <template v-else-if="scope.row.status === 9">
                                    <el-button type="text" @click="resetHandle(scope.row.id)">
                                        启动证书 <i class="el-icon-reading"></i>
                                    </el-button>
                                </template>
                                <template v-else>
                                    <el-button type="text" @click="updateHandle(scope.row.id)">
                                        更新证书 <i class="el-icon-refresh"></i>
                                    </el-button>
                                    <el-divider direction="vertical"></el-divider>
                                    <el-button type="text" @click="revokeHandle(scope.row.id)">
                                        撤销证书 <i class="el-icon-sold-out"></i>
                                    </el-button>
                                    <el-divider direction="vertical"></el-divider>
                                    <el-button type="text" @click="forbidHandle(scope.row.id)">
                                        禁用证书 <i class="el-icon-close-notification"></i>
                                    </el-button>
                                </template>
                            </template>
                            <template v-else>
                                <el-button type="text" @click="selectHandle(scope.row)">
                                    选择 <i class="el-icon-check"></i>
                                </el-button>
                            </template>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        layout="total, sizes, prev, pager, next, jumper"
                        :page-sizes="[10, 20, 50, 100]"
                        :current-page="tableOptions.page"
                        :page-size="tableOptions.limit"
                        :total="tableOptions.total">
                </el-pagination>
            </template>
            <!--popup search-->
            <el-dialog title="子菜单" :visible.sync="menuVisible" center width="70%" append-to-body :before-close="closeMenu">
                <Menu2_Equips :params="searchOptions.searchParams"></Menu2_Equips>
            </el-dialog>
            <el-dialog :title="editOptions.id?'修改数据':'添加数据'" :visible.sync="editFormVisible" width="85%" center append-to-body :before-close="closeEditForm">
                <EditForm v-if="editOptions.open" v-model="editOptions" :id="editOptions.id" @close="closeEditForm"></EditForm>
            </el-dialog>
            <el-dialog :visible.sync="detailPanelVisible" center width="70%" append-to-body :before-close="closeDetailPanel">
                <DetailPanel v-if="detailOptions.open" v-model="detailOptions" :id="detailOptions.id" title="详细信息"></DetailPanel>
            </el-dialog>
            <el-dialog :title="'证书更新['+editForm.equipName+']'" :visible.sync="editFormVisible" center append-to-body :before-close="handleCloseEditform">
                <el-form :model="editForm" ref="editForm" :rules="rules" label-width="140px" class="demo-ruleForm">
                    <el-form-item label="更新开始时间">
                        <el-form-item prop="waitregStartdate">
                            <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" v-model="editForm.waitregStartdate"
                                            style="width: 100%;"></el-date-picker>
                        </el-form-item>
                    </el-form-item>
                    <el-form-item label="更新结束时间">
                        <el-form-item prop="waitregEnddate">
                            <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" v-model="editForm.waitregEnddate"
                                            style="width: 100%;"></el-date-picker>
                        </el-form-item>
                    </el-form-item>

                    <el-form-item size="large">
                        <el-button type="primary" @click="submitHandle('editForm')">确认</el-button>
                        <el-button @click="resetForm('editForm')">取消</el-button>
                    </el-form-item>
                </el-form>
            </el-dialog>
            <el-dialog :title="'修改预注册时间['+editForm.equipName+']'" :visible.sync="regFormVisible" center append-to-body :before-close="handleCloseRegform">
                <el-form :model="editForm" ref="regForm" :rules="rules" label-width="140px" class="demo-ruleForm">
                    <el-form-item label="预注册开始时间">
                        <el-form-item prop="waitregStartdate">
                            <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" v-model="editForm.waitregStartdate"
                                            style="width: 100%;"></el-date-picker>
                        </el-form-item>
                    </el-form-item>
                    <el-form-item label="预注册结束时间">
                        <el-form-item prop="waitregEnddate">
                            <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" v-model="editForm.waitregEnddate"
                                            style="width: 100%;"></el-date-picker>
                        </el-form-item>
                    </el-form-item>

                    <el-form-item size="large">
                        <el-button type="primary" @click="submitRegHandle('regForm')">确认</el-button>
                        <el-button @click="resetForm('regForm')">取消</el-button>
                    </el-form-item>
                </el-form>
            </el-dialog>
        </el-main>
    </el-container>
</template>

<script>
    import SearchForm from "../common/OptionalSearchForm";
    import Menu2_Equips from "./Menu2_Equips";
    import EditForm from "../common/EditForm";
    import DetailPanel from "../common/DetailPanel";
    import {Search_Type} from "../../constants";
    const prefix = '/equip';
    const getListUrl = `${prefix}/list`;
    const getOneUrl = (id) => `${prefix}/one/${id}`
    const getDetailUrl = (id) => `${prefix}/detail/${id}`
    const postUrl = `${prefix}/save`;
    const putUrl = (id) => `${prefix}/update/${id}`
    const enableUrl = (id)=>`${prefix}/enable/${id}`
    const disableUrl = (id)=>`${prefix}/disable/${id}`
    const certUpdateUrl = (id)=> `${prefix}/cert/update/${id}`;
    const certRevokeUrl = (id)=> `${prefix}/cert/revoke/${id}`;
    const certForbidUrl = (id)=> `${prefix}/cert/forbid/${id}`;
    const certResetUrl = (id)=> `${prefix}/cert/reset/${id}`;
    const certUpdateRegUrl = (id)=> `${prefix}/cert/updatereg/${id}`;
    export default {
        components: {SearchForm,Menu2_Equips,EditForm,DetailPanel},
        data(){
            return{
                Search_Type,
                tableOptions:{
                    tableData:[],
                    page: 1,
                    limit: 10,
                    total: 0,
                },
                searchOptions:{
                    searchForm: {},
                    searchParams:{},
                },
                editOptions: {
                    postUrl: postUrl,
                    getOneUrl: getOneUrl,
                    putUrl: putUrl,
                },
                detailOptions: {
                    getDetailUrl : getDetailUrl,
                },
                editFormVisible: false,
                menuVisible: false,
                detailPanelVisible: false,
                regFormVisible: false,
                editForm: {},
                rules:{
                    waitregStartdate: [
                        { required: true, message: '请输入开始时间', trigger: 'blur'},
                    ],
                    waitregEnddate: [
                        { required: true, message: '请输入结束时间', trigger: 'blur'},
                    ],
                },
            }
        },
        props:['params','selector'],
        created() {
            this.g_setupMetadataConfig(this, 'Menu2_Equips')
            if(!this.params){
                if(this.tableOptions.items.some(item=>item.type === Search_Type.DynamicSelect)){
                    this.loadDynamicSelectData()
                }else{
                    this.getTableData();
                }
            }
        },
        methods: {
            loadDynamicSelectData(){
                let needLoad = true;
                //加载动态键值对
                this.tableOptions.items.filter(item=>item.type === Search_Type.DynamicSelect).forEach(item=>{
                    let dynamicUrl = item.dynamicUrl;
                    if(!dynamicUrl || item.props) return;
                    needLoad = false
                    this.$axios.get(dynamicUrl).then(res=>{
                        try {
                            let params = item.dynamicParams.split(',')
                            if(params.length !== 2) throw 'error parameter num'
                            item.p1 = params[0]
                            item.p2 = params[1]
                            item.props = res.data
                        }catch (e) {
                            console.log(e)
                        }
                        this.getTableData()
                    })
                })
                if(needLoad) this.getTableData()
            },
            getTableData(){
                this.$axios.get(getListUrl,{
                    params: {
                        ...this.g_getPage(this.tableOptions.page,this.tableOptions.limit),
                        ...this.searchOptions.searchForm
                    }
                }).then(res=>{
                    this.tableOptions.tableData = res.data.records;
                    this.tableOptions.page = res.data.current;
                    this.tableOptions.limit = res.data.size;
                    this.tableOptions.total = res.data.total;
                })
            },
            handleSizeChange(limit){
                this.tableOptions.limit = limit;
                this.getTableData();
            },
            handleCurrentChange(page){
                this.tableOptions.page = page;
                this.getTableData();
            },
            handleSearch(){
                this.tableOptions.page = 1;
                this.getTableData();
            },
            enableHandle(id){
                this.$axios.put(enableUrl(id)).then(()=>{
                    this.$message({
                        showClose: true,
                        message: `恭喜你，操作成功`,
                        type: 'success',
                        duration: 1500,
                        onClose:() => {
                            this.getTableData();
                        }
                    });
                })
            },
            disableHandle(id){
                this.$axios.put(disableUrl(id)).then(()=>{
                    this.$message({
                        showClose: true,
                        message: `恭喜你，操作成功`,
                        type: 'success',
                        duration: 1500,
                        onClose:() => {
                            this.getTableData();
                        }
                    });
                })
            },
            closeMenu(done){
                this.searchOptions.searchParams = []
                done();
            },
            openMenu(id){
                this.menuVisible = true;
                this.searchOptions.searchParams = [{name:'id',value:id}];
            },
            openDetail(id){
                this.detailOptions.id = id;
                //this.detailOptions.initData = row
                this.detailPanelVisible = true
            },
            editHandle(id){
                this.editOptions.id = id
                this.editFormVisible = true
            },
            closeEditForm(result){
                this.editOptions.id = null
                this.editFormVisible = false
                if(result){
                    this.getTableData()
                }
            },
            closeDetailPanel(){
                this.detailOptions.id = null
                this.detailPanelVisible = false
            },
            selectHandle(row){
                this.$emit('onselect', row)
            },
            updateHandle(id){
                this.$axios.get(getOneUrl(id)).then(res=>{
                    this.editForm = res.data;
                    this.editFormVisible = true;
                });
            },
            updateRegHandle(id){
                this.$axios.get(getOneUrl(id)).then(res=>{
                    this.editForm = res.data;
                    this.regFormVisible = true;
                });
            },
            revokeHandle(id){
                this.$axios.put(certRevokeUrl(id)).then(()=>{
                    this.$message({
                        showClose: true,
                        message: `恭喜你，操作成功`,
                        type: 'success',
                        duration: 1500,
                        onClose:() => {
                            this.getTableData()
                        }
                    });
                })
            },
            forbidHandle(id){
                this.$axios.put(certForbidUrl(id)).then(()=>{
                    this.$message({
                        showClose: true,
                        message: `恭喜你，操作成功`,
                        type: 'success',
                        duration: 1500,
                        onClose:() => {
                            this.getTableData()
                        }
                    });
                })
            },
            resetHandle(id){
                this.$axios.put(certResetUrl(id)).then(()=>{
                    this.$message({
                        showClose: true,
                        message: `恭喜你，操作成功`,
                        type: 'success',
                        duration: 1500,
                        onClose:() => {
                            this.getTableData()
                        }
                    });
                })
            },
            submitHandle(formName){
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if(this.editForm.id){
                            this.$axios.put(certUpdateUrl(this.editForm.id),this.editForm).then(res=>{
                                this.$message({
                                    showClose: true,
                                    message: '恭喜你，操作成功',
                                    type: 'success',
                                    duration: 1500,
                                    onClose:() => {
                                        this.getTableData()
                                    }
                                });
                                this.resetForm('editForm');
                            });
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            submitRegHandle(formName){
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if(this.editForm.id){
                            this.$axios.put(certUpdateRegUrl(this.editForm.id),this.editForm).then(res=>{
                                this.$message({
                                    showClose: true,
                                    message: '恭喜你，操作成功',
                                    type: 'success',
                                    duration: 1500,
                                    onClose:() => {
                                        this.getTableData()
                                    }
                                });
                                this.resetForm('regForm');
                            });
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(formName){
                this.$refs[formName].resetFields();
                this.editFormVisible = false;
                this.regFormVisible = false;
                this.editForm = {}
            },
            handleCloseEditform(done){
                this.resetForm('editForm');
                done();
            },
            handleCloseRegform(done){
                this.resetForm('regForm');
                done();
            }
        }
    }
</script>

<style scoped>

</style>